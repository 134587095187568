import Swiper, { Pagination, Navigation } from 'swiper';

const TestimonialSwiper = function () {
    let testimonialSwipers = document.querySelectorAll('.testimonial-swiper');
    testimonialSwipers.forEach(testimonial => {;
        let testimonialSwiper = new Swiper(testimonial, {
            loop: true,
            centeredSlides: true,
            slidesPerView: 1,
            spaceBetween: 30,
            autoHeight: true,
            modules: [Navigation, Pagination],
            navigation: {
                nextEl: '.testimonial-swiper .swiper-button-next',
                prevEl: '.testimonial-swiper .swiper-button-prev',
            },

            pagination: {
                el: '.testimonial-swiper .swiper-pagination',
                clickable: true
            },

            breakpoints: {
                992: {
                    allowTouchMove: false,
                    slidesPerView: 2.25,
                    spaceBetween: 130
                }
            }
        });
        
    });
}

export default TestimonialSwiper;
