import Swiper, { Pagination, Navigation } from 'swiper';

const BasicSwiper = function () {
    let basicSwipers = document.querySelectorAll('.basic-swiper');
    if ( basicSwipers) {
        basicSwipers.forEach(basic => {;
            let basicSwiper = new Swiper(basic, {
                loop: true,
                centeredSlides: true,
                slidesPerView: 1,
                spaceBetween: 30,
                autoHeight: true,
                modules: [Navigation, Pagination],
                navigation: {
                    nextEl: '.basic-swiper .swiper-button-next',
                    prevEl: '.basic-swiper .swiper-button-prev',
                },
    
                pagination: {
                    el: '.basic-swiper .swiper-pagination',
                    clickable: true
                },
    
                breakpoints: {
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                        // autoHeight: true
                    },
                    1192: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    }
                }
            });
            
        });
    }
}

export default BasicSwiper;
