import { NavBar, Collapse } from "bootstrap";
import "@lottiefiles/lottie-player";

import Header from "./header";
import NumbersGrid from "./numbers-grid";
import TestimonialSwiper from "./testimonials-swiper";
import BasicSwiper from "./basic-swiper";
import ImageGrid from "./image-grid";
import Quiz from "./quiz";
import CaseStudies from "./case-studies";
import LottieAnimation from "./lottie-animation";
import MapToFreedom from "./map-to-freedom";
import Animations from "./animations";
// import CalculatorFunnel from "./calculator-funnel";
// import CalculatorFunnelV2 from "./calculator-funnel-v2";
import CalculatorFunnelV3 from "./calculator-funnel-v3";
import Accordion from "./accordion-section";

Header();
TestimonialSwiper();
BasicSwiper();
ImageGrid();
Quiz();
CaseStudies();
LottieAnimation();
MapToFreedom();
// CalculatorFunnel();
// CalculatorFunnelV2();
CalculatorFunnelV3();
Animations();
NumbersGrid();
Accordion();