import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const NumbersGrid = function () {
    const animationDuration = 1500;
    const frameDuration = 1000 / 60;
    const totalFrames = Math.round( animationDuration / frameDuration );
    const easeOutQuad = t => t * ( 2 - t );

    const animateCountUp = el => {
        let frame = 0;
        const countTo = parseInt( el.innerHTML, 10 );
        const counter = setInterval( () => {
            frame++;
            const progress = easeOutQuad( frame / totalFrames );
            const currentCount = Math.round( countTo * progress );

            if ( parseInt( el.innerHTML, 10 ) !== currentCount ) {
                el.innerHTML = currentCount;
            }

            if ( frame === totalFrames ) {
                clearInterval( counter );
            }
        }, frameDuration );
    };

    let numberGrids = document.querySelectorAll('.numbers-grid');
    gsap.registerPlugin(ScrollTrigger);        

    if ( numberGrids ) {
        numberGrids.forEach((el, i) => {
            const runAnimations = () => {
                const countupEls = document.querySelectorAll( '.number-val' );
                countupEls.forEach( animateCountUp );
            };
            let timeline = gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    onEnter: runAnimations,
                    start: "50% 100%",
                    end: "100% 100%",
                    scrub: 0.5,
                    once: true,
                    // markers: true
                }
            });
        });
    }
}

export default NumbersGrid;
