const Quiz = function () {
    const wrapper = document.querySelector('.blocks-wrapper');

    if ( wrapper ) {
        const backButton = document.querySelector('.back-button');
        const nextButton = document.querySelector('.next-button');
        const submitButton = document.querySelector('.submit-button');    
        const resultsContainer = document.querySelector('.results-container');    

        let mastermind = wrapper.querySelector(".result--mastermind");
        let real_estate = wrapper.querySelector(".result--real_estate");
        let alternate = wrapper.querySelector(".result--alternate");
        
        let counter = 0;
        let shift = 0;
        let blocks = document.querySelectorAll('.quiz-blocks__block');
        let answerKeys = [];
    
    
        function checkCounter () {
            if ( counter == ( blocks.length - 1 ) ) {
                submitButton.setAttribute('disabled', true);
            } else if ( counter >= blocks.length - 2 ) {
                nextButton.setAttribute('disabled', true);
                submitButton.classList.add("ready");
                submitButton.removeAttribute('disabled');
            } 
            else {
                nextButton.removeAttribute('disabled');
                submitButton.setAttribute('disabled', true);
                submitButton.classList.remove("ready");
            }
    
            if ( counter == 0 ) {
                backButton.setAttribute('disabled', true);
            } else {
                backButton.removeAttribute('disabled', true);
            }
        }

        
        checkCounter();
    

        blocks.forEach(block => {
            let answers = block.querySelectorAll('.answer');
            answers.forEach(answer => {
                answer.addEventListener('click', function () {
                    answers.forEach(el => {
                        el.classList.remove('active');
                    });
                    answer.classList.add('active');
                    if ( counter >= blocks.length - 2 ) {
                        // Scroll down for submit button
                        const yOffset = -650; 
                        const y = submitButton.getBoundingClientRect().top + window.pageYOffset + yOffset;
                        window.scrollTo({top: y, behavior: 'smooth'});
                    }
                });
            });
        });
    
        function updateArray () {
            answerKeys = [];
            let answers = wrapper.querySelectorAll('.answer');
            answers.forEach(answer => {
                answerKey = answer.getAttribute('data-key');
                if ( answer.getAttribute('class') == 'answer active' ) {
                    answerKeys.push(answerKey);
                }

                showResults();
                
            });
        }   
        
        function showResults () {
            if ( answerKeys.includes("1-d") ) {
                mastermind.style.display = "block";
            }
            if ( answerKeys.includes("2-a") ) {
                real_estate.style.display = "block";
            }

            if ( answerKeys.length == 0 ) {
                alternate.style.display = "block";
            }

            if ( !(answerKeys.includes("1-d")) && !(answerKeys.includes("2-a")) ) {
                alternate.style.display = "block";
            } else {
                alternate.style.display = "none";
            }
        }
    
        submitButton.addEventListener('click', function () {
            updateArray();
            console.log(answerKeys);
        });
    
    
        nextButton.addEventListener('click', function () {
            counter++;
            shift = counter*100;
            checkCounter();
            wrapper.style.transform = "translateX(-" + shift + "vw)";
            return counter;
        });
        
        backButton.addEventListener('click', function () {
            counter--;
            shift = counter*100;
            checkCounter();
            wrapper.style.transform = "translateX(-" + shift + "vw)";
            return counter;
        });
    
        submitButton.addEventListener('click', function () {
            counter++;
            shift = counter*100;
            checkCounter();
            wrapper.style.transform = "translateX(-" + shift + "vw)";
            showResults();
            
            resultsContainer.style.opacity = "0";
            setTimeout(() => {
                resultsContainer.style.display = "none";
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            }, 400);

            return counter;
        });
    }
}

export default Quiz;
