const MapToFreedom = function () {
    let accordions = document.querySelectorAll('.m2fAccordion');
    let topLevel = document.querySelectorAll('.top_level-accordion');
    let topLevelButtons = document.querySelectorAll('.top-level-button');
    // accordions.forEach(accordion => {
    //     console.log(accordion);
    // });
    function closeAll (accordionName) {
        accordionName.forEach(accordion => {
            let buttons = accordion.querySelectorAll('.accordion-button');
            let collapses = accordion.querySelectorAll('.accordion-collapse');
            buttons.forEach(button => {
                button.classList.add('collapsed');
                button.setAttribute('aria-expanded', false);
            });
            collapses.forEach(collapse => {
                collapse.classList.remove('show');
            });
        });
    }

    topLevel.forEach(level => {
        let topButton = level.querySelector('.top-level-button');
        let topBody = level.querySelectorAll('.m2fAccordion');
        topButton.addEventListener('click', function () {
            if ( topButton.getAttribute('aria-expanded') == 'false' ) {
                closeAll(topBody);
            }
        });
    });
    
    // topLevelButtons.forEach(top => {
    //     top.addEventListener('click', function () {
    //         if (top.getAttribute('aria-expanded') == 'false') {
    //             topLevel.forEach(el => {
    //                 console.log(el.closest('.m2fAccordion'));
    //             });
    //         }
    //     });
    // }); 

    let m2fClose = document.querySelectorAll('.m2fClose');
    m2fClose.forEach(el => {
        el.addEventListener('click', function () {
            closeAll(accordions);
            closeAll(topLevel);
        });
    });
}


export default MapToFreedom;