const CalculatorFunnelV3 = function () {
    let calcFunnel = document.querySelector('#calculator-funnel');
    if ( calcFunnel ) {
        // Loader
        loadingCalc = document.querySelector('#loadingCalc');
    
        // Observer
        let mList = document.querySelector('#hidden-fields'),
        options = {
            childList: true,
            subtree: true
        },
        observer = new MutationObserver(mCallback);
    
        function mCallback(mutations) {
            for (let mutation of mutations) {
                if (mutation.type === 'childList') {
                    pageLoaded();
                }
            }
        }
    
        if ( mList ) {
            observer.observe(mList, options);
        }


        const btnNext = document.querySelector("#calc-btn-next");
        const btnPrev = document.querySelector("#calc-btn-prev");

        function buildForms () {
            let hsArray = [];
            let hsFields = document.querySelectorAll('.hs-input');
            hsFields.forEach(field => {
                hsArray.push(field);
            });

            let wpArray = [];
            let wpFields = document.querySelectorAll('.wp-form-field');
            wpFields.forEach(field => {
                wpArray.push(field);
            });

            let selects = document.querySelectorAll("input");
            selects.forEach(el => {
                el.addEventListener("mousewheel", function (event) {
                    this.blur()
                });
            });
            
            function matchFields () {
                let checkArray = [];
                hsArray.forEach(item => {
                    checkArray.push(item.value);
                });

                let hsForm = document.querySelector("#hidden-fields");
                let submitButton = hsForm.querySelector('.hs_submit');
                let richtext = hsForm.querySelector('.hs-richtext');

                // Turn Back On
                submitButton.classList.add('disabled');

                for ( let i = 0; i < wpArray.length; i++ ) {
                    wpArray[i].addEventListener("change", function () {
                        let init_input = this.value;
                        hsArray[i].setAttribute('value', init_input);
                        checkArray.splice(i, 1, hsArray[i].value)
                        if ( checkArray.includes("") ) {
                            submitButton.classList.add('disabled');
                        } else {
                            submitButton.classList.remove('disabled');
                        }
                    });
                }
            }
            
            matchFields();
        }


        function moveSteps () {
            let section = document.querySelector('.calc-cards-sec');
            let calcCards = document.querySelectorAll(".calc-cards__card");
            const step1 = document.querySelector("#step1");

            let hsForm = document.querySelector("#hidden-fields");
            let submitButton = hsForm.querySelector('.hs_submit');
            let richtext = hsForm.querySelector('.hs-richtext');

            let compStyles = window.getComputedStyle(step1);
            let posStart = compStyles.getPropertyValue('left');

            if (posStart = "0") {   
                btnPrev.setAttribute('disabled', true);
            }

            function checkPos () {
                if (step1.style.left != "0%") {   
                    btnPrev.removeAttribute('disabled');
                } else {
                    btnPrev.setAttribute('disabled', true);
                }

                if (step1.style.left == "-200%") {   
                    btnNext.setAttribute('disabled', true);
                    submitButton.style.display = "block";
                    richtext.style.display = "block";
                    section.style.minHeight = 750 + 'px';
                    section.style.height = 'auto';
                } else {
                    btnNext.removeAttribute('disabled');
                    submitButton.style.display = "none";
                    richtext.style.display = "none";
                    section.style.minHeight = 920 + "px";
                }
            }

            let moveNext = () => {
                calcCards.forEach(card => {
                    const left =  card.style.left || 0;
                    card.style.left = `${parseInt(left, 10) - 100}%`;
                });
                checkPos();
            }
            
            let movePrev = () => {
                calcCards.forEach(card => {
                    const left =  card.style.left || 0;
                    card.style.left = `${parseInt(left, 10) + 100}%`;
                });
                checkPos();
            }
            
            btnNext.addEventListener("click", moveNext)
            btnPrev.addEventListener("click", movePrev)


            // Submit Function // 
            let bannerSec = document.querySelector(".banner-simple-sec");
            let step4 = document.querySelector('#step4');
            const calcButtons = document.querySelector('.calc-buttons');

            submitButton.addEventListener("click", function () {
                moveNext();
                let calcCardsContainer = document.querySelector('.calc-cards');
                
                calcButtons.style.display = "none";
                section.classList.add('end');
                calcCardsContainer.classList.add('end');
                step4.classList.add('end');
                
                setTimeout(() => {
                    document.querySelector("#scrollTo").scrollIntoView();
                }, 400);
                
                showResults();
            });
        }

        function showResults () {   
            getScores();
            let metrics = document.querySelectorAll('.metric__block');
            let grade = document.querySelector('.metric__grade');
            metrics.forEach(metric => {
                grade.classList.add('show');
                metric.classList.add('show');
            });

            mList.style.display = "none";
            
        }

        function getScores () {
            let collections = document.querySelector("input[name='calculator_stated_collections']");
            let expense = document.querySelector("input[name='calculator_stated_expenses']");
            
            // Expense Score
            let expense_score = document.querySelector('#expense_score');
            let expense_qual = document.querySelector('#expense_qual');
            let newScore = expense_score.querySelector('span');
            let collections_val = collections.value;
            let expense_val = expense.value;
            let expense_ratio = Math.ceil(((expense_val / collections_val)) * 100) / 100;
            newScore.innerHTML = expense_ratio;

            // Grade Total
            let grade_total = 0;
            
            function expenseCalc ( qual ) {
                if ( expense_ratio >= 0.75 ) {  
                    qual.innerHTML = "Very Poor";
                    grade_total = 1;
                    expense_score.classList.add('red');
                    expense_qual.classList.add('red');
                }
                if ( expense_ratio < 0.75 && expense_ratio >= 0.70 ) {  
                    qual.innerHTML = "Needs Improvement";
                    grade_total = 2;
                    expense_score.classList.add('red');
                    expense_qual.classList.add('red');
                }
                if ( expense_ratio < 0.70 && expense_ratio >= 0.60 ) {  
                    qual.innerHTML = "Pretty Good";
                    grade_total = 3;
                }
                if ( expense_ratio < 0.6 && expense_ratio >= 0.50 ) {  
                    qual.innerHTML = "Very Good";
                    grade_total = 4;
                    expense_score.classList.add('green');
                    expense_qual.classList.add('green');
                }
                if ( expense_ratio < 0.5 ) { 
                    qual.innerHTML = "Excellent";
                    grade_total = 5;
                    expense_score.classList.add('green');
                    expense_qual.classList.add('green');
                }
            }
            expenseCalc(expense_qual);


            // Profit Multipliers
            let pm_array = [];
            let pm_score = document.querySelector('#pm_score');
            let pm_numerator = pm_score.querySelector('span');
            let pm_qual = document.querySelector('#pm_qual');

            let doc_total = document.querySelector("input[name='number_of_doctors']");
            let doc_value = doc_total.value;

            let associates = document.querySelector("input[name='number_of_associate_doctors']");
            let ass_value = associates.value;

            let average_ass = Math.round( ass_value / doc_value );
            let hyg = document.querySelector("input[name='number_of_hygienists']");
            let hyg_value = hyg.value;

            let average_hyg = Math.round( hyg_value / doc_value );
            let ops = document.querySelector("input[name='number_of_operatories']");
            let owned = document.querySelector("input[name='practice_owned']");

            assTotal = () => {
                let pm_total_1 = 0;
                if ( average_ass >= 2 ) {
                    pm_total_1 = 1;
                } else if ( average_ass < 2 && average_ass >= 1) {
                    pm_total_1 = 0.5;  
                } else {
                    pm_total_1 = 0;
                }
                pm_array.push(pm_total_1);
            }
            
            hygTotal = () => {
                if ( average_hyg >= 4 ) {
                    pm_total_2 = 1.5;
                } else if ( average_hyg < 4 && average_hyg >= 2 ) {
                    pm_total_2 = 1;
                } else if ( average_hyg < 2 ) {
                    pm_total_2 = 0.5;
                }
                pm_array.push(pm_total_2);
            }

            opsTotal = () => {
                if ( ops.value >= 7 ) {
                    pm_total_3 = 1.5;
                } else if ( ops.value < 7 && ops.value >= 5) {
                    pm_total_3 = 1; 
                } else if ( ops.value < 5 && ops.value >= 3) {
                    pm_total_3 = 0.5; 
                } else {
                    pm_total_3 = 0;
                }
                pm_array.push(pm_total_3);
            }

            ownedTotal = () => {
                if ( owned.value == "yes" ) {
                    pm_total_4 = 1;
                } else {
                    pm_total_4 = 0;
                }
                pm_array.push(pm_total_4);
            }

            assTotal();
            hygTotal();
            opsTotal();
            ownedTotal();
            
            pm_total = pm_array.reduce((a, b) => a + b, 0);
            pm_numerator.innerHTML = pm_total;

            if ( pm_total <= 1 ) { 
                pm_qual.innerHTML = "Very Poor";
                pm_qual.classList.add('red');
                pm_score.classList.add('red');
            }
            if ( pm_total >= 1 && pm_total < 3 ) { 
                pm_qual.innerHTML = "Needs Improvement";
                pm_qual.classList.add('red');
                pm_score.classList.add('red');
            }
            if ( pm_total >= 3 && pm_total <= 3.5 ) { 
                pm_qual.innerHTML = "Pretty Good";
            }
            if ( pm_total > 3.5 && pm_total < 4.5 ) { 
                pm_qual.innerHTML = "Very Good"
                pm_qual.classList.add('green');
                pm_score.classList.add('green');
            }
            if ( pm_total >= 4.5 ) { 
                pm_qual.innerHTML = "Excellent";
                pm_qual.classList.add('green');
                pm_score.classList.add('green');
            }


            // Practice Details
            let practice_array = [];

            let prac_total = document.querySelector("input[name='number_of_practices']");
            let prac_value = prac_total.value;
            let average_docs = Math.round(doc_value / prac_value);
            let prac_score = document.querySelector('#prac_score');
            let prac_numerator = prac_score.querySelector('span');
            let prac_qual = document.querySelector('#prac_qual');

            doctorsTotal = () => {
                let prac_total_2 = 0;
                if ( average_docs >= 3 ) {
                    prac_total_2 = 5;
                } else if ( average_docs < 3 && average_docs >= 2 ) {
                    prac_total_2 = 3;  
                } else {
                    prac_total_2 = 1;  
                }
                practice_array.push(prac_total_2);
            }

            doctorsTotal();

            practice_total = practice_array.reduce((a, b) => a + b, 0);
            prac_numerator.innerHTML = practice_total;

            if ( practice_total <= 2 ) { 
                prac_qual.innerHTML = "Very Poor";
                prac_qual.classList.add('red');
                prac_score.classList.add('red');
            }
            if ( practice_total > 2 && practice_total <= 3 ) {
                prac_qual.innerHTML = "Needs Improvement";
                prac_qual.classList.add('red');
                prac_score.classList.add('red');
            }
            if ( practice_total > 3 && practice_total <= 3.5 ) {
                prac_qual.innerHTML = "Pretty Good"
            }
            if ( practice_total > 3.5 && practice_total < 4.5 ) {
                prac_qual.innerHTML = "Very Good";
                prac_qual.classList.add('green');
                prac_score.classList.add('green');
            }
            if ( practice_total >= 4.5 ) {
                prac_qual.classList.add('green');
                prac_score.classList.add('green');
                prac_qual.innerHTML = "Excellent"
            }
            

            // Get Final Grade
            let grade_qual = document.querySelector('#grade_qual');
            let grade_score = document.querySelector('#grade_score');
            getGrade = () => {
                let gradeSum = practice_total + pm_total + grade_total;
                if ( gradeSum == 15 ) {
                    grade_qual.innerHTML = "Extremely Profitable";
                    grade_score.innerHTML = "A+";
                    grade_score.classList.add('green');
                }
                if ( gradeSum < 15 && gradeSum >= 13.5 ) {
                    grade_qual.innerHTML = "Highly Profitable";
                    grade_score.innerHTML = "A";
                    grade_score.classList.add('green');
                }
                if ( gradeSum < 13.5 && gradeSum >= 12 ) {
                    grade_qual.innerHTML = "Pretty Profitable";
                    grade_score.innerHTML = "B";
                    grade_score.classList.add('green');
                }
                if ( gradeSum < 12 && gradeSum >= 10.5 ) {
                    grade_qual.innerHTML = "Somewhat Profitable";
                    grade_score.innerHTML = "C";
                }
                if ( gradeSum < 10.5 && gradeSum >= 9 ) {
                    grade_qual.innerHTML = "Not Very Profitable";
                    grade_score.innerHTML = "D";
                    grade_score.classList.add('red');
                }
                if ( gradeSum < 9 ) {
                    grade_qual.innerHTML = "Not Profitable";
                    grade_score.innerHTML = "F";
                    grade_score.classList.add('red');
                }
            }
            getGrade();
        }




        function pageLoaded () {
            const calcForm = document.querySelector('#hbspt-form-bc954753-55da-44228957-f87cf7694002')
            loadingCalc.style.display = "none";
            loadingCalc.setAttribute('aria-busy', 'false');
            buildForms();
            moveSteps();
        }
    }
}

export default CalculatorFunnelV3;
