const LottieAnimation = function () {
    // Make it Crispy
    document.addEventListener("DOMContentLoaded", function(){
        let elements = document.getElementsByTagName('lottie-player');
        if ( elements ) {
            for (var i = 0; i < elements.length; i++) {
                elements[i].addEventListener('play', (event) => {
                    event.target.shadowRoot.querySelector('svg').style.transform = '';
                });
                elements[i].play(); // trigger (again)
            }
        }
    });
}


export default LottieAnimation;