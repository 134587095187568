const Accordion = function () {
    let accordion = document.querySelector('#accordion-section');
    if (accordion) {
        let first = accordion.querySelector('#collapse-0');
        let button = accordion.querySelector('#accordion-button-0');
        first.classList.add('show');
        button.classList.remove('collapsed');
        button.setAttribute('aria-expanded', true);

        // // Auto scrolling
        // let buttons = accordion.querySelectorAll('.accordion-button');
        // if ( buttons ) {
        //     buttons.forEach(button => {
        //         button.addEventListener('click', function () {
        //             if ( button.getAttribute('aria-expanded') == 'true' ) {
        //                 setTimeout(() => {
        //                     const y = button.getBoundingClientRect().top + window.scrollY;
        //                     window.scroll({
        //                         top: y - 200,
        //                         behavior: 'smooth'
        //                     });
        //                 }, "300");
        //             }
        //         });
        //     });
        // }
    }

}

export default Accordion;
