const Header = function () {
    const body = document.querySelector('body');
    const navbar = document.querySelector('.navbar');
    const takeover = document.querySelector('#nav-takeover')
    const toggle = document.querySelector('#navbar-toggle');
    const icon = document.querySelector('#navbar-icon');
    const close = document.querySelector('#navbar-icon__close');
    const overlay = document.querySelector('.to-overlay')

    toggle.addEventListener('click', function () {
        
        if (icon.getAttribute('class') == 'navbar-icon d-flex flex-column active') {
            takeover.classList.remove('active');
            icon.classList.remove('active');
            navbar.classList.remove('active');

            // When the modal is hidden, we want to remain at the top of the scroll position
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            document.documentElement.style = "scroll-behavior: auto";
            window.scrollTo(0, parseInt(scrollY || '0') * -1);

            overlay.classList.remove('active');
            
        } else {
            takeover.classList.add('active');
            takeover.scrollTo({top: 0});
            icon.classList.add('active');
            navbar.classList.add('active');

            // When the modal is shown, we want a fixed body
            let scrollPosY = `-${window.scrollY}px`;
            document.body.style.position = 'fixed';
            document.body.style.top = scrollPosY;
            overlay.classList.add('active');
        }
    })
}

export default Header;
