import Swiper, { Pagination, Navigation } from 'swiper';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Lottie from "lottie-web";

const CaseStudies = function () {
    // Case Studies Page Full Page Swiper
    let caseContainer = document.querySelector('.case-study-container');
    if ( caseContainer ) {
        const caseSwiper = new Swiper('.swiper', {
            loop: true,
            centeredSlides: true,
            slidesPerView: 1,
            spaceBetween: 0,
            autoHeight: true,
            modules: [Navigation, Pagination],
            navigation: {
                nextEl: caseContainer.querySelector('.swiper-button-next'),
                prevEl: caseContainer.querySelector('.swiper-button-prev')
            },
    
            pagination: {
                el: caseContainer.querySelector('.swiper-pagination'),
                clickable: true
            },
    
            breakpoints: {
                992: {
                    allowTouchMove: false
                }
            }
        });
    }



    let caseStudy = document.querySelector('.single-case-study');
    if ( caseStudy ) {

        /////  Anchors  /////
        const csButton = document.querySelector('.cs-hero__button');
        let anchors = document.querySelectorAll('.cs-anchor-link');
        let anchorWrap = document.querySelector('#anchor-wrap');

        let challengeLink = document.querySelector('#cs-challenge');
        let challengePos = challengeLink.getBoundingClientRect().top + window.scrollY;

        let solutionLink = document.querySelector('#cs-solution');
        let solutionPos = solutionLink.getBoundingClientRect().top + window.scrollY;

        let resultsLink = document.querySelector('#cs-results');
        let resultsPos = resultsLink.getBoundingClientRect().top + window.scrollY;


        function scrollPosition (topPos, leftPos) {
            window.scrollTo({
                top: topPos - 64,
                behavior: 'smooth'
            });
            anchorWrap.scrollTo({
                left: leftPos,
                behavior: 'smooth'
            });
        }

        csButton.addEventListener('click', function () {
            // scrollPosition(challengePos, 0);
            scrollPosition(solutionPos, 0); /* Turn on for basic layout */
        });

        anchors.forEach(anchor => {
            anchor.addEventListener('click', function () {
                anchors.forEach(el => {
                    el.classList.remove('active');
                });
                anchor.classList.add('active');

                if ( anchor.classList.contains('link-solution') ) {
                    scrollPosition(solutionPos, 65);
                } 
                else if ( anchor.classList.contains('link-results') ) {
                    scrollPosition(resultsPos, 140);
                } 
                else {
                    scrollPosition(challengePos, 0);
                }
            });
        });
    



        /////  Scroll Trigger  /////
        let csHero = document.querySelector('.cs-hero-sec');
        let csChallenge = document.querySelector('.cs-challenge');
        let csSolution = document.querySelector('.cs-solution-sec');
        let csResults = document.querySelector('.cs-results-sec');

        let anchorChallenge = document.querySelector('.link-challenge');
        let anchorSolution = document.querySelector('.link-solution');
        let anchorResults = document.querySelector('.link-results');

        gsap.registerPlugin(ScrollTrigger);

        /////  Watermarks /////
        let watermarkLeft = gsap.timeline(
            {
            scrollTrigger: {
                trigger: csHero,
                start: "-80px 0%",
                end: "90% 0%",
                immediateRender: false,
                reversed: true,
                scrub: 1,
            }
        });

        let watermarkRight = gsap.timeline(
            {
            scrollTrigger: {
                trigger: csHero,
                start: "-80px 0%",
                end: "90% 0%",
                immediateRender: false,
                reversed: true,
                scrub: 1,
                // markers: true,
            }
        });
        const left = document.querySelector('.lines-left');
        const right = document.querySelector('.lines-right');
        watermarkLeft
            .to( left, { translateX: '-100%', duration : 0.75 ,  ease : 'power1' } );
        watermarkRight
            .to( right, { translateX: '100%', duration : 0.75 ,  ease : 'power1' } );






        /////  Challenge  /////
        function challengeActive () {
            anchors.forEach(el => {
                el.classList.remove('active');
            });
            anchorChallenge.classList.add('active');
            anchorWrap.scrollTo({
                left: 0,
                behavior: 'smooth'
            });
        }

        let challenges = gsap.timeline({
            scrollTrigger: {
                trigger: csChallenge,
                onEnter: challengeActive,
                start: "0% 100%",
                end: "100% 0%",
                immediateRender: false,
                reversed: true,
                scrub: 1,
                // markers: true,
            }
        });





        /////  Solutions  /////
        function solutionActive () {
            anchors.forEach(el => {
                el.classList.remove('active');
            });
            anchorSolution.classList.add('active');
            anchorWrap.scrollTo({
                left: 65,
                behavior: 'smooth'
            });
        }

        let solutions = gsap.timeline({
            scrollTrigger: {
                trigger: csSolution,
                onEnter: solutionActive,
                onLeaveBack: challengeActive,
                start: "5% 80%",
                end: "450px 100%",
                immediateRender: false,
                reversed: true,
                scrub: 3,
                // markers: true,
            }
        });

        let highlights = document.querySelectorAll('.cs-solution__highlight');
        highlights.forEach(highlight => {
            solutions
                .to( highlight, { translateX: '0%', duration : 2 ,  ease : 'power1' } );
        });





        /////  Results  /////
        function resultsActive () {
            anchors.forEach(el => {
                el.classList.remove('active');
            });
            anchorResults.classList.add('active');
            anchorWrap.scrollTo({
                left: 140,
                behavior: 'smooth'
            });
        }
        
        let results = gsap.timeline({
            scrollTrigger: {
                trigger: csResults,
                onEnter: resultsActive,
                onLeaveBack: solutionActive,
                start: "0% 90%",
                end: "40% 100%",
                immediateRender: false,
                reversed: true,
                scrub: 1,
                // markers: true,
            }
        });

        let quotes = document.querySelectorAll('blockquote');
        quotes.forEach((quote) => {
            gsap.to(quote, {
                scrollTrigger: {
                    trigger: quote,
                    start: '0% 85%',
                    end: '0% 100%',
                    reversed: false,
                    scrub: false,
                    // markers: true,
                },

                translateY: '0%', 
                opacity: 1,
                duration : 1,  
                ease : 'power2.out'
            });    
        });

        /////  Results Swiper  /////
        let resultsSwipers = document.querySelectorAll('.cs-results-swiper');
        let buttonsMobile = document.querySelector('.results-buttons-mobile');
        let buttonsDesktop = document.querySelector('.results-buttons-desktop');
        let paginationMobile = document.querySelector('.results-pag-mobile');
        let paginationDesktop = document.querySelector('.results-pag-desktop');

        resultsSwipers.forEach(basic => {;
            let resultsSwiper = new Swiper(basic, {
                loop: true,
                centeredSlides: true,
                slidesPerView: 1,
                spaceBetween: 30,
                autoHeight: true,
                modules: [Navigation, Pagination],
                navigation: {
                    nextEl: buttonsMobile.querySelector('.swiper-button-next'),
                    prevEl: buttonsMobile.querySelector('.swiper-button-prev'),
                },
    
                pagination: {
                    el: paginationMobile,
                    clickable: true
                },
    
                breakpoints: {
                    992: {
                        slidesPerView: 1,
                        spaceBetween: 50,
                        autoHeight: true,
                        navigation: {
                            nextEl: buttonsDesktop.querySelector('.swiper-button-next'),
                            prevEl: buttonsDesktop.querySelector('.swiper-button-prev'),
                        },
                        pagination: {
                            el: paginationDesktop,
                        }
                    }
                }
            });
        });


    }
    // End Case Study conditional
}

export default CaseStudies;
