import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const Animations = function () {
    gsap.registerPlugin(ScrollTrigger);

    // Basic Content
    let basicContentSections = document.querySelectorAll('.basic-content');
    if (basicContentSections) {
        basicContentSections.forEach((section) => {
            let container = section.querySelector('.container');
            let header = section.querySelector('h2.animate');
            let subheader = section.querySelector('h4.animate');
            let body = section.querySelector('.basic-content__body.animate');

            gsap.to(header, {
                scrollTrigger: {
                    trigger: container,
                    start: '0% 85%',
                    end: '0% 100%',
                    reversed: false,
                    scrub: false,
                    // markers: true,
                },
    
                translateY: '0%', 
                opacity: 1,
                duration : 1,  
                ease : 'power2.out'
            });

            gsap.to(body, {
                scrollTrigger: {
                    trigger: container,
                    start: '0% 85%',
                    end: '0% 100%',
                    reversed: false,
                    scrub: false,
                    // markers: true,
                },
    
                translateY: '0%', 
                opacity: 1,
                duration : 1,  
                delay: 0.2,
                ease : 'power2.out'
            });    
        });    
    }



    // 3 Col Grid
    let grid3Col = document.querySelectorAll('.grid-3-col-sec');
    if (grid3Col) {
        grid3Col.forEach((section) => {
            let blocks = section.querySelectorAll('.grid-3-col__block');
            blocks.forEach((block, i) => {
                i++;
                gsap.to(block, {
                    scrollTrigger: {
                        trigger: block,
                        start: '0% 85%',
                        end: '0% 100%',
                        reversed: false,
                        scrub: false,
                        // markers: true,
                    },

                    delay: i * 0.1,
                    translateY: '0%', 
                    opacity: 1,
                    duration : 1,  
                    ease : 'power2.out'
                });
                
            });
        });    
    }


    // Numbers Grid
    let numbersGrid = document.querySelectorAll('.numbers-grid-sec');
    if (numbersGrid) {
        numbersGrid.forEach((section) => {
            let blocks = section.querySelectorAll('.numbers-grid__block');
            blocks.forEach((block, i) => {
                i++;
                gsap.to(block, {
                    scrollTrigger: {
                        trigger: section,
                        start: '0% 85%',
                        end: '0% 100%',
                        reversed: false,
                        scrub: false,
                        // markers: true,
                    },

                    delay: i * 0.1,
                    translateY: '0%', 
                    opacity: 1,
                    duration : 1,  
                    ease : 'power2.out'
                });
            });
        });    
    }
}

export default Animations;
